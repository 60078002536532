import ITEMS from '../../../constant/headerItems';

export default function HeaderItems({ className = '' }) {
    return (
        <div className={`header-items ${className}`}>
            {ITEMS.map(({ link, title, icon }) => (
                <a  key={title} target='blank' href={link}>
                    <div className='header-items__item'>
                        {icon()}
                    </div>
                </a>
            ))}
        </div>
    )
}
