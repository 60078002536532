
import { useEffect, useRef } from 'react';
import FooterTop from './footerTop';

// var yDown = null;

export default function Footer({ teaserRef }) {
    const ref = useRef(null)
    // useEffect(() => {
    //     window.addEventListener('wheel', (e) => {
    //         if (e.deltaY > 0 && !ref.current.classList.contains('active')) {
    //             ref.current.classList.add('active')
    //             teaserRef.current.classList.add('active')

    //         }
    //         else if (e.deltaY < 0 && ref.current.classList.contains('active')) {
    //             ref.current.classList.remove('active')
    //             teaserRef.current.classList.remove('active')
    //         }
    //     })
    //     document.addEventListener('touchstart', handleTouchStart, false);
    //     document.addEventListener('touchmove', handleTouchMove, false);
    // }, [])

    // function getTouches(evt) {
    //     return evt.touches ||             // browser API
    //         evt.originalEvent.touches; // jQuery
    // }

    // function handleTouchStart(evt) {
    //     const firstTouch = getTouches(evt)[0];
    //     yDown = firstTouch.clientY;
    // };

    // function handleTouchMove(evt) {
    //     if (!yDown) {
    //         return;
    //     }

    //     var yUp = evt.touches[0].clientY;

    //     var yDiff = yDown - yUp;

    //     if (yDiff > 0 && !ref.current.classList.contains('active')) {
    //         /* down swipe */
    //         ref.current.classList.add('active')
    //         // teaserRef.current.classList.add('active')
    //     } else if (yDiff < 0 && ref.current.classList.contains('active')) {
    //         /* up swipe */
    //         ref.current.classList.remove('active')
    //         // teaserRef.current.classList.remove('active')
    //     }
    //     /* reset values */
    //     yDown = null;
    // };
    return (
        <footer ref={ref} className="teaser-footer">
            <div className="container">
                <FooterTop />
            </div>
        </footer>
    )
}
