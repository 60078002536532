const networks = {
  mainnet: {
    chainId: `0x${Number(1).toString(16)}`,
  },
  ropsten: {
    chainId: `0x${Number(3).toString(16)}`,
  },
};

export default networks;
