import { useEffect, useRef, useState } from 'react';
import videoDesktop from '../../../assets/videos/landing.mp4';
import videoTablet from '../../../assets/videos/landing-tablet.mp4';
import videoMobile from '../../../assets/videos/landing-mobile.mp4';
import Content from './content';
import Header from './header';
import Footer from './footer';
import StartAnimation from './startAnimation';

const initial = (window.innerWidth > 992) ? 'desktop' : window.innerWidth > 768 ? 'tablet' : window.innerWidth > 0 ? 'mobile' : 'desktop'
export default function Index() {
    const ref = useRef(null)
    const teaserRef = useRef(null)
    const [display, setDisplay] = useState(initial);
    const [check, setCheck] = useState(false);
    useEffect(() => {
        window.addEventListener('resize', (e) => {
            if (e.target.innerWidth > 992) {
                setDisplay('desktop')
            }
            else if (e.target.innerWidth > 768) {
                setDisplay('tablet')
            }
            else if (e.target.innerWidth > 0) {
                setDisplay('mobile')
            }
        })
    }, [])

    useEffect(() => {
        if (ref.current) {
            ref.current.pause();
            setTimeout(() => {
                ref.current.currentTime = 0;
            }, 6000);
            setTimeout(() => {
                ref.current.play();
            }, 6800);
        }
        check && window.location.reload()
        setCheck(true)
    }, [display]);

    return (
        <>
            <div ref={teaserRef} className="teaser">
                <StartAnimation />
                <Header />
                <Content />
                {
                    display === 'tablet' ?
                        <video poster="./images/video-bg-tablet.jpg" ref={ref} muted playsInline autoPlay loop className='teaser__video'>
                            <source src={videoTablet} type='video/mp4' />
                        </video>
                        : display === 'mobile' ?
                            <video poster="./images/video-bg-mobile.jpg" ref={ref} muted playsInline autoPlay loop className='teaser__video'>
                                <source src={videoMobile} type='video/mp4' />
                            </video>
                            :
                            <video poster="./images/video-bg.jpg" ref={ref} muted playsInline autoPlay loop className='teaser__video'>
                                <source src={videoDesktop} type='video/mp4' />
                            </video>
                }
            </div>
            <Footer teaserRef={teaserRef} />
        </>
    )
}
