import React, { useEffect } from "react";
import ReactDOM from "react-dom";

const Modal = props => {
    const closeOnEscapeKeyDown = e => {
        if ((e.charCode || e.keyCode) === 27) {
            props.onClose();
        }
    };

    useEffect(() => {
        document.body.addEventListener("keydown", closeOnEscapeKeyDown);
        return function cleanup() {
            document.body.removeEventListener("keydown", closeOnEscapeKeyDown);
        };
    }, []);

    return ReactDOM.createPortal(
        <div className={`modal ${props.show ? 'enter-done' : ''}`} onClick={props.onClose}>
            <div className="modal-border">
                <div className="modal-content" onClick={e => e.stopPropagation()}>
                    {props.title ? <div className="modal-title">{props.title}</div> : ''}
                    <div className="modal-body">{props.body}</div>
                    <button onClick={props.onClick} className="modal-btn">
                        {props.btn}
                    </button>
                </div>
            </div>
        </div>
        ,
        document.getElementById("root")
    );
};

export default Modal;
