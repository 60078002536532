import axios from "axios";

const BEARER_TOKEN = 'Bearer 96a17067-0521-4f64-a0cf-b7c93f2db0f3'

const postCoda = axios.create({
    baseURL: "https://coda.io/apis/v1/docs/GpV-7h3XbI/tables/Olymp/rows",
    headers: {
        'Authorization': BEARER_TOKEN,
        'Content-Type': 'application/json'
    }
});

export { postCoda };



