import React from 'react';
import {  _TEXT_OLYMPUS, _TEXT_HOME, _TEXT_NFT } from '../../../assets/icons/icons';

export default function StartAnimation() {
    return (
        <>
            <div className="start-animation__color"></div>
            <div className='start-animation'>
                <div className="start-animation__body">
                    <div className="start-animation__first">
                        <div>
                            <img src="/images/logo-animation.png" alt="" />
                        </div>
                    </div>
                    <div className='start-animation__bg'></div>
                    <div className="start-animation__second">
                        <div className="start-animation__second__text">
                            {_TEXT_HOME()}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
