import './assets/sass/index.scss';
import Teaser from './components/pages/teaser';

function App() {
  return (
    <>
      <Teaser/>
    </>
  );
}

export default App;
