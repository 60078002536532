import { ethers } from "ethers";
import changeNetwork from "./changeNetwork";
import truncate from "./truncate";
import { postCoda } from "../assets/api";

const connectWallet = () => {
  const handleNetworkSwitch = async (networkName) => {
    await changeNetwork({ networkName });
  };

  window.ethereum.on("chainChanged", (_chainId) => {
    connectFunc();
  });

  const date = new Date().toLocaleDateString();

  const sendData = (userAddress, ether) => {
    const test = postCoda.post('/', {
      "rows": [
        {
          "cells": [
            {
              "column": "c-a78qQrON7-",
              "value": userAddress
            },
            {
              "column": "c-A5U1fAjIwt",
              "value": ether
            },
            {
              "column": "c-k-EK_S9p5d",
              "value": date
            }
          ]
        }
      ]
    });
    test.then(res => {
      console.log(res);
    }
    )
  }

  const connectFunc = async () => {
    try {
      if (!window.ethereum) {
        return {
          status: "not_installed",
          title: "Metamask is not installed",
          body: "Please install Metamask to continue",
          btn: "CLOSE",
        };
      }
      const provider = new ethers.providers.Web3Provider(
        window.ethereum,
        "any"
      );
      await provider.send("eth_requestAccounts", []);
      const { chainId } = await provider.getNetwork();

      if (chainId === 1) {
        const signer = provider.getSigner();
        const userAddress = await signer.getAddress();
        const balance = await provider.getBalance(userAddress);
        const ether = ethers.utils.formatEther(balance);

        if (ether >= 0.1) {
          sendData(userAddress, ether);
          return {
            status: "connected",
            title: truncate(userAddress,15),
            body: "Please check back on 05.02.2022",
            btn: "GO HOME",
          };
        } else {
          return {
            status: "low",
            title: "",
            body: "To avoid bots, your wallet should hold at least 0.1 ETH to participate in the raffle",
            btn: "CLOSE",
          };
        }
      } else {
        handleNetworkSwitch("mainnet");
        throw new Error("Wrong network");
      }
    } catch (err) {
      return {
        status: "error",
        title: "Connection error",
        body: "Please connect to Metamask to continue",
        btn: "CLOSE",
      };
    }
  };

  return connectFunc();

};

export default connectWallet;
