
const truncate = function (fullStr, strLen, separator) {
    if (fullStr?.length <= strLen) return fullStr;
  
    separator = separator || '...';
  
    var sepLen = separator.length,
      charsToShow = strLen - sepLen,
      frontChars = Math.ceil((2 * charsToShow) / 3),
      backChars = Math.floor(charsToShow / 3);
  
    return fullStr?.substr(0, frontChars) + separator + fullStr?.substr(fullStr?.length - backChars);
  };

  export default truncate