import { useState, useEffect } from "react";
import { ethers } from "ethers";
import { _LOGO, _THUNDER } from "../../../assets/icons/icons";
import ConnectBtn from "./connectBtn";
import HeaderItems from "./headerItems";
import Modal from "./modal";
import connectWallet from "../../../utils/connectWallet";

export default function Header() {
  const [connected, setConnected] = useState(false);
  const [show, setShow] = useState(false);
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [btn, setBtn] = useState("");
  const [onClick, setOnClick] = useState();

  const clearAccount = () => {
    setConnected(false);
    openConnectModal();
  };

  if (window.ethereum) {
    window.ethereum.on("disconnect", clearAccount);
  }

  // check if user is connected
  useEffect(() => {
    const checkConnection = async () => {
      if (window.ethereum) {
        const provider = new ethers.providers.Web3Provider(
          window.ethereum,
          "any"
        );
        const accounts = await provider.listAccounts();
        setConnected(accounts.length > 0);
      }
    };
    checkConnection();
  }, []);

  const notConnectedModalInfo = () => {
    setBody(
      "Connect your wallet for your chance to win one of 200 whitelist spots"
    );
    setBtn("CONNECT WALLET");
    setOnClick(() => handleClick);
  };

  const openConnectModal = () => {
    if (!connected) {
      notConnectedModalInfo();
      setShow(true);
    } else {
      handleClick();
    }
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleClick = async () => {
    const connectStatus = await connectWallet();
    setShow(false);

    setTitle(connectStatus.title);
    setBody(connectStatus.body);
    setBtn(connectStatus.btn);
    setOnClick(() => handleClose);
    setShow(true);
  };

  return (
    <header>
      <div className="container">
        <div className="teaser-header">
          <span className="teaser-header__info">COMING SOON</span>
          <img className="teaser-header__logo" src="/images/logo.png" alt="logo" />
          <div className="teaser-header__items">
            <HeaderItems />
            {/* <ConnectBtn onSetShow={openConnectModal} /> */}
            <Modal
              onClose={() => setShow(false)}
              show={show}
              title={title}
              body={body}
              btn={btn}
              onClick={onClick}
            />
          </div>
        </div>
      </div>
    </header>
  );
}
