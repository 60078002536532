import { _INSTAGRAM } from "../../../assets/icons/icons";

export default function Content() {
    return (
        <div className="teaser-content">
            <div className="container">
                <div className="teaser-content-row">
                    <div className="teaser-content-row__left">
                        <a target='blank' href="https://www.instagram.com/avihoo_tattoo">
                            <span className="teaser-content-row__left__content">
                                BY AVIHOO BEN GIDA {_INSTAGRAM()}
                            </span>
                        </a>
                    </div>
                    <div className="teaser-content-row__right">
                       COMING SOON
                    </div>
                </div>
            </div>
        </div>
    )
}
