
import { _DISCORD, _INSTAGRAM, _TWITTER } from '../assets/icons/icons';

const ITEMS = [
    {
        title : 'instagram',
        icon : _INSTAGRAM,
        link : 'https://www.instagram.com/olympusgodsnft/'
    },
    {
        title : 'twitter',
        icon : _TWITTER,
        link : 'https://twitter.com/OlympusgodsNft'
    },
    {
        title : 'discord',
        icon : _DISCORD,
        link : 'https://discord.gg/olympusgods'
    },

]

export default ITEMS