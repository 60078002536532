import Subscribe from './footerSubscribe';
import NewsletterSubscribe from './newsLetterSubscribe';
import { _INSTAGRAM } from '../../../assets/icons/icons';

export default function FooterTop() {
    return (
        <div className="teaser-footer-top">
            <div className="teaser-footer-top__left">
                {/* <NewsletterSubscribe/> */}
                <a target='blank' href="https://www.instagram.com/avihoo_tattoo">
                    <span className="teaser-content-row__left__content">
                        BY AVIHOO BEN GIDA {_INSTAGRAM()}
                    </span>
                </a>
            </div>
            <div>
                <span className="teaser-footer-top__title">
                    OLYMPUS GODS ALL RIGHTS RESERVED.
                </span>
            </div>
        </div>
    )
}
