import networks from "../constant/networks";

const changeNetwork = async ({ networkName }) => {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [
          {
            ...networks[networkName],
          },
        ],
      });
    } catch (err) {
        console.log(err.message);
    }
  };

  export default changeNetwork;